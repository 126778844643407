
@import url('https://fonts.googleapis.com/css?family=Rubik:300,300i,400,400i,500,500i,700,700i,900,900i&display=swap&subset=cyrillic');
@import url('https://fonts.googleapis.com/css2?family=Raleway&family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import "ag-grid-community/dist/styles/ag-grid.css";
@import "ag-grid-community/dist/styles/ag-theme-alpine.css";
@import "ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin";

$fa-font-path: "../webfonts";

@import "bootstrap/scss/bootstrap";
@import '~bootstrap/dist/css/bootstrap.css';

@import "prismjs/plugins/toolbar/prism-toolbar.css";
@import "prismjs/themes/prism-okaidia";

.ag-theme-alpine .ag-header { //color del header del gridview
  --ag-header-height: 1000px;
    --ag-header-foreground-color: white;
    --ag-header-background-color: rgb(86, 86, 86);
    --ag-header-cell-hover-background-color: rgb(86, 86, 86);
    --ag-header-cell-moving-background-color: rgb(86, 86, 86);
}

.overlay-Spinner{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Fondo semitransparente para un efecto de superposición */
  z-index: 9999; /* Asegura que el spinner esté en el frente */
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-check-input {
  clear: left;
}

.form-switch.form-switch-sm {
  margin-bottom: 0.5rem; /* JUST FOR STYLING PURPOSE */
}

.form-switch.form-switch-sm .form-check-input {
  height: 1rem;
  width: calc(1rem + 0.75rem);
  border-radius: 2rem;
}

.form-switch.form-switch-md {
  margin-bottom: 1rem; /* JUST FOR STYLING PURPOSE */
}

.form-switch.form-switch-md .form-check-input {
  height: 1.5rem;
  width: calc(2rem + 0.75rem);
  border-radius: 3rem;
}

.form-switch.form-switch-lg {
  margin-bottom: 1.5rem; /* JUST FOR STYLING PURPOSE */
}

.form-switch.form-switch-lg .form-check-input {
  height: 2rem;
  width: calc(3rem + 0.75rem);
  border-radius: 4rem;
}

.form-switch.form-switch-xl {
  margin-bottom: 2rem; /* JUST FOR STYLING PURPOSE */
}

.form-switch.form-switch-xl .form-check-input {
  height: 2.5rem;
  width: calc(4rem + 0.75rem);
  border-radius: 5rem;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  html {
    margin: 0 !important;
    overflow: auto !important;
}

 html,
  body {
    height: 100%;
    background-color: #fdfdfd !important;
    font-family: 'Raleway', sans-serif;
  }

  h1,h2,h3,h4,h5,h6 {
    color: #31313b;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    margin: 20px 0;
}

.card-principal {
    background-color: #FFF;
    border-radius: 14px;
    box-shadow: 0 3px 30px 0 #e4e4e4;
    padding: 15px;
    z-index: 0
}
.card-table {
    background-color: #FFF;
    border-radius: 14px;
    box-shadow: 0 3px 12px 0 #e4e4e4;
    padding: 15px;
    z-index: 0
}

.espacio-body{
  padding-top: 128px;
}

.spacer-70 {
    height: 70px;
  }
  
  .spacer-60 {
    height: 60px;
  }
  .spacer-50 {
    height: 50px;
  }
  
  .spacer-30 {
    height: 30px;
  }
  
  .spacer-20 {
    height: 20px;
  }

  .ag-theme-alpine {
    @include ag-theme-alpine((
        // use theme parameters where possible

        // Default border for cells. This can be used to specify the border-style and border-color properties e.g. `dashed red` but the border-width is fixed at 1px.
        cell-horizontal-border: solid ag-derived(secondary-border-color),
    ));

    .ag-header-cell {
        // or write CSS selectors to make customisations beyond what the parameters support
        border-right: 1px solid ag-param(secondary-border-color);
    }
}